<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists"
  >
    <div>
      <div class="filtering">
        <div class="search-toggle">
          <p>
            {{ $t('SearchBy') }}
          </p>
          <div>
            <p
              class="active2"
            >
              {{ $t('MaterialName') }}
            </p>
          </div>
        </div>
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchByName == ''"
              class="search1"
              style=" position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchByName != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchByName = ''"
            />
            <input
              v-model="searchByName"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('MaterialName')"
              :style="getFilteredMaterials.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilteredMaterials.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilteredMaterials.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredMaterials"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
            <!-- <div
              v-if="getFilteredMaterials.length == 0"
              class="search-item"
            >
              <p>Sorry. No Results.</p>
            </div> -->
          </div>
        </div>
      </div>
      <div style="width: 100%; padding-top: 16px">
        <div style="width: 100%; height: 85vh; overflow-y: scroll;">
          <ul class="ul-order">
            <li
              v-for="item in getInvertoryOverview"
              :key="item.id"
            >
              <div class="order-card">
                <p style="width:15%;text-align: left; ">
                  <span class="order-card__title">{{ $t('MaterialName') }}:</span>
                  <span class="createdInput">{{ item.materialName }}</span>
                </p>
                <p style="width:15%;text-align: left;">
                  <span class="order-card__title">{{ $t('Created') }}: </span>
                  <span class="createdInput">{{ momDate(item.created) }}</span>
                </p>
                <b-button
                  v-show="item.materialName.length >= 1"

                  style="height: 37px; width:197px; border-radius:6px;margin-top: 7px;border-color:transparent "
                  variant="outline-primary"
                  :class="{
                    'activee': item.materialName === nameActive,
                  }"
                  @click="showMoreId(item.materialName, item.materialId)"
                >
                  {{ $t('ShowMore') }}
                </b-button>
              </div>
              <ul
                v-show="item.materialName === nameActive"
              >

                <li>
                  <span style="font-weight: 600">{{ $t('MaterialVariantsName') }}:</span>
                  <div
                    class="order-card__wrapper"
                  >
                    <div
                      v-for="products in getMaterialVariant"
                      :key="products.materialVariantId"
                      class="order-card__inside"
                    >
                      <p
                        class=""
                        style="display: flex;justify-content: space-between;"
                      >
                        <span style="color: #344054; font-weight: 500">{{ $t('ColorName') }}:</span> <span>{{ products.colorName }}</span>
                      </p>
                      <p
                        class=""
                        style="display: flex;justify-content: space-between;"
                      >
                        <span style="color: #344054; font-weight: 500">{{ $t('MaterialVariantName') }}:</span> {{ products.materialVariantName }}
                      </p>
                      <p
                        class=""
                        style="display: flex;justify-content: space-between;"
                      >
                        <span style="color: #344054; font-weight: 500">{{ $t('CurrentAmount') }}:</span> {{ products.currentAmount }}
                      </p>
                      <p
                        class=""
                        style="display: flex;justify-content: space-between;"
                      >
                        <span style="color: #344054; font-weight: 500">{{ $t('Daystodeliver') }}:</span> {{ products.daysToDeliver }}
                      </p>
                      <p style="display: flex;justify-content: space-between;">
                        <span style="color: #344054; font-weight: 500">{{ $t('Description') }}:</span> {{ products.description }}
                      </p>
                      <p style="display: flex;justify-content: space-between;">
                        <span style="color: #344054; font-weight: 500">{{ $t('MeasurementType') }}:</span> {{ products.measurementType }}
                      </p>
                      <p style="display: flex;justify-content: space-between;">
                        <span style="color: #344054; font-weight: 500">{{ $t('MainSupplier') }}:</span> {{ products.supplierName }}
                      </p>
                      <p
                        v-if="products.supplierTwoByVariantOverviewDto != null"
                        style="display: flex;justify-content: space-between;"
                      >
                        <span style="color: #344054; font-weight: 500">{{ $t('SecondarySupplier') }}:</span> {{ products.supplierName }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <b-pagination
                      v-if="getMatVarTotalItems > 15"
                      v-model="pageForMV"
                      :total-rows="getMatVarTotalItems"
                      :per-page="pageSizeForMV"
                      first-number
                      last-number
                      align="fill"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                    >
                      <template #prev-text>
                        <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
                      </template>
                      <template #next-text>
                        <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
                      </template>
                    </b-pagination>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div>

      <div>
        <div>
          <b-pagination
            v-if="getTotalItemsForMaterials > 15"
            v-model="page"
            :total-rows="getTotalItemsForMaterials"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            prev-class="prev-itemi"
            next-class="next-itemi"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'


export default {
  components: {

  },
  data() {
    return {
      showMore: false,
      nameActive: '',
      buttonActive: '',
      noSearch: false,
      searchByName: '',
      materialId: '',
      totalItems: '',
      page: 1,
      pageSize: 15,
      pageForMV: 1,
      pageSizeForMV: 20,
      arrowCounter: 0,
      enterPressed: false,
    }
  },
  computed: {
    ...mapGetters(['getInvertoryOverview', 'getTotalItemsForMaterials', 'getCurrentPageForMaterials', 'getFilteredMaterials', 'getMaterialVariant', 'getMatVarTotalItems', 'getMatVarCurrentPage']),
  },
  watch: {
    page(value) {
      this.loadInventoryOverview({
        pageNumber: value,
        pageSize: this.pageSize,
        materialName: this.searchByName,
      })
    },
    pageForMV(value) {
      this.loadMaterialVariant({
        id: this.materialId,
        pageNumber: value,
        pageSize: this.pageSize,
        successCallback: () => {
          // // this.changeLoadingtoTrue(true)
        },
      })
    },
    searchByName(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadInventoryOverview({
            pageNumber: this.page,
            pageSize: this.pageSize,
            materialName: value,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredMaterialsByName({ materialName: value, measurementType: null });
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['loadInventoryOverview', 'filteredMaterialsByName', 'changeLoadingtoTrue', 'resetFilteredMaterials', 'loadMaterialVariant', 'resetLoadMaterialVariant']),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.loadInventoryOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          materialName: this.searchByName,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredMaterials.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredMaterials = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredMaterials[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    showMoreId(name, materialId) {
      if (this.nameActive === name) {
        this.nameActive = ''
      } else {
        this.nameActive = name
        this.resetLoadMaterialVariant()
        this.loadMaterialVariant({
          id: materialId,
          pageNumber: 1,
          pageSize: this.pageSize,
          successCallback: () => {
            // // this.changeLoadingtoTrue(true)
          },
        })
      }
      this.materialId = materialId
    },

    momDate(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm')
    },
    async searchByOn(value) {
      this.searchByName = value
      this.noSearch = false
      await this.loadInventoryOverview({
        pageNumber: this.page,
        pageSize: this.pageSize,
        materialName: this.searchByName,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredMaterials();
    },
  },
}
</script>

<style scoped lang="scss">
  .order-card__title {
    font-weight: 500;
    font-size: 16px;
  display: block;
  padding: 0px 0px;
  text-align: left;
  margin: 0px;
  color: #344054;
  }
  .order-card__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 20px;
  }
  .order-card__inside {
    border: 1px solid #D0D5DD;
    box-shadow: none;
    border-radius: 5px;
    margin-top: 15px;
    padding: 10px;
    margin-bottom: 15px;
    position: relative;
  }
  .createdInput {
  border: 1px solid #D0D5DD;
  display: flex;
  min-height: 40px;
  width: 100%;
  border-radius: 6px;
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  align-items: center;
}
 .pm-li {
    list-style: none;
    background: #ffffff;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid transparent;
    box-shadow: none;
    padding: 12px 10px;
    margin-top: 20px;

  }
  .pm-li h5 {
    margin-bottom: 0;
  }
  .ul-order {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .ul-order li {
    background: #ffffff;
    overflow-y: auto;

    border-radius: 8px;
    border: 1px solid #DADBDC;
    padding: 6px 2px 6px 10px;
    margin-top: 20px;
  }
  .ul-order ul {
    padding-left: 5px;
  }

  .order-card {
    display: flex;
    align-items: center;
  }

  .order-card p {
    margin-right: 20px;
  }
  .stage-card {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid transparent;
    box-shadow: none;
    padding: 10px;
    margin-bottom: 15px;

    margin-right: 10px;
  }
  .stage-card.active {
    background-color: #c5b6ff;
  }
  .color-card {
    margin-right: 10px;
    cursor: pointer;
    padding: 2px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
  }
  .color-card.active {
    background-color: #dbdbdb;
  }
  .active2{
  font-weight: bold;
  color: $base-color;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px !important;
  }
}
 @media screen and (max-width: 1190px) {
  .order-card {
    p {
      width: auto !important;
    }
    button {
      height: 40px !important;
      width: auto !important;
    }
  }
 }
 .is-active {
  background-color: #dedede;
}
</style>
