<template>
  <div >
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main no-flex">

      <InventoryOverview />
    </section>
    <!-- <section class="main no-flex">
      <MaterialVariant />
    </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from '@/components/Navbar.vue'
import InventoryOverview from '@/components/InventoryOverview.vue'
import { mapGetters } from 'vuex'

// import MaterialVariant from '@/views/MaterialVariant.vue'
// import MaterialVariant from '@/views/MaterialVariant.vue'

export default {
  name: 'Home',
  components: {
    // Sidebar,
    // Navbar,
    InventoryOverview,
    // MaterialVariant,
  },
  data() {
    return {
      materialIdToShow: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1340px){
  .main{
    padding: 60px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1190px) {
.main {
  padding: 90px 0.75rem 15px 0.75rem;
}
 }
</style>
